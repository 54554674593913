import _ from '@/utils/lodash'
import { APIFilter } from '@/utils/api'
import { TABLA, TALMACEN, } from '@/utils/consts'
import { currentDateTime } from '@/utils/date'
import { v4 as uuidv4 } from 'uuid'

let pendingCalls = []

const downloadParte = async (Vue, idparteTrabajo) => {
  Vue.$loading.setMessage('Descargando parte de trabajo')
  const apiFilter = new APIFilter()
  apiFilter.addExact('idparte_trabajo', idparteTrabajo)
  const response = (
    await Vue.$api.call('parteTrabajo.selectSync', { filter: apiFilter })
  )
  let parte = response.data.result.dataset[0]
  parte.fdescarga = currentDateTime()
  return response.data.result.dataset[0]
}

const annadirCliente = async (Vue, idcliente) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idcliente', idcliente)
  pendingCalls.push({ name: 'cliente', method: 'cliente.select', params: { filter: apiFilter } })
}

const annadirSistema = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'sistema', method: 'sistema.selectSync', params: { filter: apiFilter } })
}

const annadirFicheroSistema = async (Vue, idsistema, idtecnico) => {
  const apiFilter = Vue.$online.fichero.buildApiFilterVisualizacion(idsistema, TABLA.sistema.idtabla, !!idtecnico, false, false)
  pendingCalls.push({ name: 'ficheroSistema', method: 'fichero.select', params: { filter: apiFilter } })
}

const downloadOrdenTrabajo = async (Vue, idordenTrabajo) => {
  Vue.$loading.setMessage('Descargando orden de trabajo')
  const apiFilter = new APIFilter()
  apiFilter.addExact('idorden_trabajo', idordenTrabajo)
  const response = (
    await Vue.$api.call('ordenTrabajo.selectSync', { filter: apiFilter })
  )
  if (response.data.result.dataset.length === 0){
    console.error(`No se puede descargar la OT ${idordenTrabajo}, mira si el usuario no tiene filtros generales`)
  }
  return response.data.result.dataset[0]
}

const annadirBancoPrecio = async (Vue, idbancoPrecio) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idbanco_precio', idbancoPrecio)
  pendingCalls.push({ name: 'bancoPrecio', method: 'bancoPrecio.select', params: { filter: apiFilter } })
}

const annadirLbancoPrecio = async (Vue, idbancoPrecio) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idbanco_precio', idbancoPrecio)
  pendingCalls.push({ name: 'lbancoPrecio', method: 'lbancoPrecio.select', params: { filter: apiFilter } })
}

const annadirBancoPrecioCapitulo = async (Vue, idbancoPrecio) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idbanco_precio', idbancoPrecio)
  pendingCalls.push({ name: 'bancoPrecioCapitulo', method: 'bancoPrecioCapitulo.select', params: { filter: apiFilter } })
}

const annadirBancoPrecioGrupoDto = async (Vue, idbancoPrecio) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idbanco_precio', idbancoPrecio)
  pendingCalls.push({ name: 'bancoPrecioGrupoDto', method: 'bancoPrecioGrupoDto.select', params: { filter: apiFilter } })
}

const annadirLbancoPrecioDetalle = async (Vue, idbancoPrecio) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idbanco_precio', idbancoPrecio)
  pendingCalls.push({ name: 'lbancoPrecioDetalle', method: 'lbancoPrecioDetalle.select', params: { filter: apiFilter } })
}

const annadirClienteDireccion = async (Vue, idcliente) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idcliente', idcliente)
  pendingCalls.push({ name: 'clienteDireccion', method: 'clienteDireccion.select', params: { filter: apiFilter } })
}

const annadirClienteTelefono = async (Vue, idcliente) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idcliente', idcliente)
  pendingCalls.push({ name: 'clienteTelefono', method: 'clienteTelefono.select', params: { filter: apiFilter } })
}

const annadirClienteAviso = async (Vue, idcliente) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idcliente', idcliente)
  pendingCalls.push({ name: 'clienteAviso', method: 'clienteAviso.select', params: { filter: apiFilter } })
}

const annadirSubsis = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'subsis', method: 'subsis.select', params: { filter: apiFilter } })
}

const annadirMaterialSistema = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'materialSistema', method: 'materialSistema.select', params: { filter: apiFilter } })
}

const annadirDatoMaterialSistema = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'datoMaterialSistema', method: 'datoMaterialSistema.select', params: { filter: apiFilter } })
}

const annadirSistemaMant = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'sistemaMant', method: 'sistemaMant.select', params: { filter: apiFilter } })
}

const annadirSistemaCuota = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'sistemaCuota', method: 'sistemaCuota.select', params: { filter: apiFilter } })
}

const annadirSistemaTelefono = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'sistemaTelefono', method: 'sistemaTelefono.select', params: { filter: apiFilter } })
}

const annadirDatoSistema = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'datoSistema', method: 'datoSistema.select', params: { filter: apiFilter } })
}

const annadirTsistemaTdatosist = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'tsistemaTdatosist', method: 'tsistemaTdatosist.select', params: { filter: apiFilter } })
}

const annadirSistemaTviacomunicacion = async (Vue, idsistema) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idsistema', idsistema)
  pendingCalls.push({ name: 'sistemaTviacomunicacion', method: 'sistemaTviacomunicacion.select', params: { filter: apiFilter } })
}


const annadirOrdenTrabajoMatsist = async (Vue, idordenTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idorden_trabajo', idordenTrabajo)
  pendingCalls.push({ name: 'ordenTrabajoMatsist', method: 'ordenTrabajoMatsist.select', params: { filter: apiFilter } })
}

const downloadChecklistOt = async (Vue, idordenTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idorden_trabajo', idordenTrabajo)
  apiFilter.addGT('estado', 0)
  const response = await Vue.$api.call('checklistOt.select', { filter: apiFilter })
  return response.data.result.dataset[0]
}

const annadirChecklistOtSubsis = async (Vue, idchecklistOt) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idchecklist_ot', idchecklistOt)
  pendingCalls.push({ name: 'checklistOtSubsis', method: 'checklistOtSubsis.select', params: { filter: apiFilter } })
}

const annadirGrupoChecklistOt = async (Vue, idchecklistOt) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idchecklist_ot', idchecklistOt)
  pendingCalls.push({ name: 'grupoChecklistOt', method: 'grupoChecklistOt.select', params: { filter: apiFilter } })
}

const annadirAgrupacionPreguntaOt = async (Vue, idchecklistOt) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idchecklist_ot', idchecklistOt)
  pendingCalls.push({ name: 'agrupacionPreguntaOt', method: 'agrupacionPreguntaOt.select', params: { filter: apiFilter } })
}

const annadirPreguntaChecklistOt = async (Vue, idchecklistOt) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idchecklist_ot', idchecklistOt)
  pendingCalls.push({ name: 'preguntaChecklistOt', method: 'preguntaChecklistOt.select', params: { filter: apiFilter } })
}

const annadirValorColumnaOt = async (Vue, idchecklistOt) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idchecklist_ot', idchecklistOt)
  pendingCalls.push({ name: 'valorColumnaOt', method: 'valorColumnaOt.select', params: { filter: apiFilter } })
}

const annadirTanomaliaChecklistOt = async (Vue, idchecklistOt) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idchecklist_ot', idchecklistOt)
  pendingCalls.push({ name: 'tanomaliaChecklistOt', method: 'tanomaliaChecklistOt.select', params: { filter: apiFilter } })
}

const annadirAccionCorrectoraOt = async (Vue, idchecklistOt) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idchecklist_ot', idchecklistOt)
  pendingCalls.push({ name: 'accionCorrectoraOt', method: 'accionCorrectoraOt.select', params: { filter: apiFilter } })
}

const annadirFicheroAnomalia = async (Vue, idchecklistOt) => {
  const apiFilter = new APIFilter()
  apiFilter
    .addExact('idchecklist_ot_anomalias', idchecklistOt)
    .addExact('idtabla', TABLA.tanomalia_checklist_ot.idtabla)
    .addGT('estado', 0)
  pendingCalls.push({ name: 'ficheroAnomalia', method: 'fichero.select', params: { filter: apiFilter } })
}

const annadirFicheroAccionCorrectora = async (Vue, idchecklistOt) => {
  const apiFilter = new APIFilter()
  apiFilter
    .addExact('idchecklist_ot_acciones_correctoras', idchecklistOt)
    .addExact('idtabla', TABLA.accion_correctora_ot.idtabla)
    .addGT('estado', 0)
  pendingCalls.push({ name: 'ficheroAccionCorrectora', method: 'fichero.select', params: { filter: apiFilter } })
}

const annadirAccionEjec = async (Vue, idordenTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idorden_trabajo', idordenTrabajo)
  pendingCalls.push({ name: 'accionEjec', method: 'accionEjec.select', params: { filter: apiFilter } })
}

const annadirOrdenTrabajoMant = async (Vue, idordenTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idorden_trabajo', idordenTrabajo)
  pendingCalls.push({ name: 'ordenTrabajoMant', method: 'ordenTrabajoMant.select', params: { filter: apiFilter } })
}

const annadirLordenTrabajo = async (Vue, idordenTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idorden_trabajo', idordenTrabajo)
  pendingCalls.push({ name: 'lordenTrabajo', method: 'lordenTrabajo.select', params: { filter: apiFilter } })
}

const annadirLordenTrabajoDetalle = async (Vue, idordenTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idorden_trabajo', idordenTrabajo)
  pendingCalls.push({ name: 'lordenTrabajoDetalle', method: 'lordenTrabajoDetalle.select', params: { filter: apiFilter } })
}

const annadirParteTrabajoMatsist = async (Vue, idparteTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idparte_trabajo', idparteTrabajo)
  pendingCalls.push({ name: 'parteTrabajoMatsist', method: 'parteTrabajoMatsist.select', params: { filter: apiFilter } })
}

const annadirParteTrabajoTecnico = async (Vue, idparteTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idparte_trabajo', idparteTrabajo)
  pendingCalls.push({ name: 'parteTrabajoTecnico', method: 'parteTrabajoTecnico.select', params: { filter: apiFilter } })
}

const annadirLparteTrabajo = async (Vue, idparteTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idparte_trabajo', idparteTrabajo)
  pendingCalls.push({ name: 'lparteTrabajo', method: 'lparteTrabajo.select', params: { filter: apiFilter } })
}

const annadirTiempoTrabajado = async (Vue, idparteTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idparte_trabajo', idparteTrabajo)
  pendingCalls.push({ name: 'tiempoTrabajado', method: 'tiempoTrabajado.select', params: { filter: apiFilter } })
}

const annadirSolicitudMaterial = async (Vue, idparteTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idparte_trabajo', idparteTrabajo)
  pendingCalls.push({ name: 'solicitudMaterial', method: 'solicitudMaterial.select', params: { filter: apiFilter } })
}

const annadirLsolicitudMaterial = async (Vue, idparteTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idparte_trabajo', idparteTrabajo)
  pendingCalls.push({ name: 'lsolicitudMaterial', method: 'lsolicitudMaterial.select', params: { filter: apiFilter } })
}

const annadirLlsolicitudMaterial = async (Vue, idparteTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idparte_trabajo', idparteTrabajo)
  pendingCalls.push({ name: 'llsolicitudMaterial', method: 'llsolicitudMaterial.select', params: { filter: apiFilter } })
}

const annadirOrdenTrabajoSubsis = async (Vue, idordenTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idorden_trabajo', idordenTrabajo)
  pendingCalls.push({ name: 'ordenTrabajoSubsis', method: 'ordenTrabajoSubsis.select', params: { filter: apiFilter } })
}

const annadirFicheroOrdenTrabajo = async (Vue, idordenTrabajo, idtecnico) => {
  const apiFilter = Vue.$online.fichero.buildApiFilterVisualizacion(idordenTrabajo, TABLA.orden_trabajo.idtabla, !!idtecnico, false, false)
  pendingCalls.push({ name: 'ficheroOrdenTrabajo', method: 'fichero.select', params: { filter: apiFilter } })
}

const annadirFicheroParteTrabajo = async (Vue, idparteTrabajo, idtecnico) => {
  const apiFilter = Vue.$online.fichero.buildApiFilterVisualizacion(idparteTrabajo, TABLA.parte_trabajo.idtabla, !!idtecnico, false, false)
  pendingCalls.push({ name: 'ficheroParteTrabajo', method: 'fichero.select', params: { filter: apiFilter } })
}

const annadirProyectoContable = async (Vue, idproyectoContable) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idproyecto_contable', idproyectoContable)
  pendingCalls.push({ name: 'proyectoContable', method: 'proyectoContable.select', params: { filter: apiFilter } })
}

const downloadAlmacenesParte = async (Vue, idparteTrabajo) => {
  const response = await Vue.$api.call('parteTrabajo.Almacenes', { idparte_trabajo: idparteTrabajo })
  return response.data.result.dataset
}

const annadirStock = async (Vue, almacenes) => {
  const apiFilter = new APIFilter()
  apiFilter.addIn('idalmacen', almacenes)
  pendingCalls.push({ name: 'stock', method: 'stock.select', params: { filter: apiFilter } })
}

const annadirLstock = async (Vue, almacenes) => {
  const apiFilter = new APIFilter()
  apiFilter.addIn('idalmacen', almacenes)
  pendingCalls.push({ name: 'lstock', method: 'lstock.select', params: { filter: apiFilter } })
}

const annadirAnticipocli = async (Vue, idordenTrabajo) => {
  const apiFilter = new APIFilter()
  apiFilter.addExact('idorden_trabajo', idordenTrabajo)
  pendingCalls.push({ name: 'anticipocli', method: 'anticipocli.select', params: { filter: apiFilter } })
}


const annadirNotaOrdenTrabajo = async (Vue, idordenTrabajo, idtecnico) => {
  const apiFilter = Vue.$online.nota.buildApiFilterVisualizacion(idordenTrabajo, TABLA.orden_trabajo.idtabla, !!idtecnico, false, false)
  pendingCalls.push({ name: 'notaOrdenTrabajo', method: 'nota.select', params: { filter: apiFilter } })
}

const annadirNotaParteTrabajo = async (Vue, idordenTrabajo, idtecnico) => {
  const apiFilter = Vue.$online.nota.buildApiFilterVisualizacion(idordenTrabajo, TABLA.parte_trabajo.idtabla, !!idtecnico, false, false)
  pendingCalls.push({ name: 'notaParteTrabajo', method: 'nota.select', params: { filter: apiFilter } })
}


const insertCliente = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando cliente')
    let row = batchResponse.data.cliente.result.dataset[0]
    await Vue.$offline.cliente.insert(row, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertCliente)`)
  }
}

const insertSistema = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando sistema')
    let row = batchResponse.data.sistema.result.dataset[0]
    await Vue.$offline.sistema.insert(row, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertSistema)`)
  }
}

const insertFicheroSistema = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando adjuntos de sistemas')
    let rows = batchResponse.data.ficheroSistema.result.dataset
    for (let row of rows) {
      row.idfichero = row.idfichero.toString()
      row.id = row.id.toString()
      try {
        row.data = await Vue.$api.ajaxDownload(row.url)
      } catch (e) {
        if (e && e.response && e.response.status >= 400) {
          // no hacer nada si no se puede descargar
          // no interpretar como un error de descarga del parte de trabajo
        } else {
          throw new Error(e)
        }
      }
    }
    await Vue.$offline.fichero.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertFicheroSistema)`)
  }
}

const insertBancoPrecio = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando banco de precios')
    let row = batchResponse.data.bancoPrecio.result.dataset[0]
    await Vue.$offline.bancoPrecio.insert(row, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertBancoPrecio)`)
  }
}

const insertLbancoPrecio = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando líneas de banco de precios')
    let rows = batchResponse.data.lbancoPrecio.result.dataset
    await Vue.$offline.lbancoPrecio.insert(rows, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertLbancoPrecio)`)
  }
}

const insertBancoPrecioCapitulo = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando capítulos de banco de precios')
    let rows = batchResponse.data.bancoPrecioCapitulo.result.dataset
    await Vue.$offline.bancoPrecioCapitulo.insert(rows, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertBancoPrecioCapitulo)`)
  }
}

const insertBancoPrecioGrupoDto = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando grupos de descuento de banco de precios')
    let rows = batchResponse.data.bancoPrecioGrupoDto.result.dataset
    await Vue.$offline.bancoPrecioGrupoDto.insert(rows, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertBancoPrecioGrupoDto)`)
  }
}

const insertLbancoPrecioDetalle = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando detalle de líneas de banco de precios')
    let rows = batchResponse.data.lbancoPrecioDetalle.result.dataset
    await Vue.$offline.lbancoPrecioDetalle.insert(rows, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertLbancoPrecioDetalle)`)
  }
}

const insertClienteDireccion = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando direcciones')
    let rows = batchResponse.data.clienteDireccion.result.dataset
    await Vue.$offline.clienteDireccion.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertClienteDireccion)`)
  }
}

const insertClienteTelefono = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando números de teléfonos')
    let rows = batchResponse.data.clienteTelefono.result.dataset
    for (let row of rows) {
      row.idcliente_telefono = row.idcliente_telefono.toString()
    }
    await Vue.$offline.clienteTelefono.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertClienteTelefono)`)
  }
}

const insertClienteAviso = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando avisos de cliente y sistema')
    let rows = batchResponse.data.clienteAviso.result.dataset
    await Vue.$offline.clienteAviso.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertClienteAviso)`)
  }
}

const insertSubsis = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando subsistemas')
    let rows = batchResponse.data.subsis.result.dataset
    for (let row of rows) {
      row.idsubsis = row.idsubsis.toString()
    }
    await Vue.$offline.subsis.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertSubsis)`)
  }
}

const insertMaterialSistema = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando material de sistema')
    let rows = batchResponse.data.materialSistema.result.dataset
    for (let row of rows) {
      row.idmaterial_sistema = row.idmaterial_sistema.toString()
      row.idsubsis = row.idsubsis.toString()
    }
    await Vue.$offline.materialSistema.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertMaterialSistema)`)
  }
}

const insertDatoMaterialSistema = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando datos de material de sistema')
    let rows = batchResponse.data.datoMaterialSistema.result.dataset
    for (let row of rows) {
      row.iddato_material_sistema = row.iddato_material_sistema.toString()
      row.idmaterial_sistema = row.idmaterial_sistema.toString()
    }
    await Vue.$offline.datoMaterialSistema.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertDatoMaterialSistema)`)
  }
}

const insertSistemaMant = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando revisiones')
    let rows = batchResponse.data.sistemaMant.result.dataset
    await Vue.$offline.sistemaMant.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertSistemaMant)`)
  }
}

const insertSistemaCuota = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando cuotas')
    let rows = batchResponse.data.sistemaCuota.result.dataset
    await Vue.$offline.sistemaCuota.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertSistemaCuota)`)
  }
}

const insertDatoSistema = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando datos de sistema')
    let rows = batchResponse.data.datoSistema.result.dataset
    for (let row of rows) {
      row.iddato_sistema = row.iddato_sistema.toString()
    }
    await Vue.$offline.datoSistema.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertDatoSistema)`)
  }
}

const insertSolicitudMaterial = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando solicitudes de material')
    let rows = batchResponse.data.solicitudMaterial.result.dataset
    await Vue.$offline.solicitudMaterial.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertsolicitudMaterial)`)
  }
}

const insertLsolicitudMaterial = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando solicitudes de material')
    let rows = batchResponse.data.lsolicitudMaterial.result.dataset
    await Vue.$offline.lsolicitudMaterial.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertlsolicitudMaterial)`)
  }
}

const insertLlsolicitudMaterial = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando solicitudes de material')
    let rows = batchResponse.data.llsolicitudMaterial.result.dataset
    await Vue.$offline.llsolicitudMaterial.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertllsolicitudMaterial)`)
  }
}

const insertTsistemaTdatosist = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando datos de sistema')
    let rows = batchResponse.data.tsistemaTdatosist.result.dataset
    await Vue.$offline.tsistemaTdatosist.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertTsistemaTdatosist)`)
  }
}

const insertSistemaTviacomunicacnion = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando vías de comuniscación')
    let rows = batchResponse.data.sistemaTviacomunicacion.result.dataset
    await Vue.$offline.sistemaTviacomunicacion.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertsiStemaTviacomunicacion)`)
  }
}

const insertSistemaTelefono = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando teléfonos de sistema')
    let rows = batchResponse.data.sistemaTelefono.result.dataset
    for (let row of rows) {
      row.idcliente_telefono = row.idcliente_telefono.toString()
      row.idsistema_telefono = row.idsistema_telefono.toString()
      // no almacenar localmente datos de CRA
      row.orden_cra = null
      row.codigo_cra = null
      row.codigo_coaccion_cra = null
    }
    await Vue.$offline.sistemaTelefono.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertSistemaTelefono)`)
  }
}

const insertOrdenTrabajoMatsist = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando material afectado')
    let rows = batchResponse.data.ordenTrabajoMatsist.result.dataset
    for (let row of rows) {
      row.idorden_trabajo_matsist = row.idorden_trabajo_matsist.toString()
      row.idmaterial_sistema = row.idmaterial_sistema.toString()
    }
    await Vue.$offline.ordenTrabajoMatsist.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertOrdenTrabajoMatsist)`)
  }
}

const insertChecklistOt = async (Vue, checklistOt) => {
  try {
    if (checklistOt) {
      Vue.$loading.setMessage('Actualizando checklist')
      await Vue.$offline.checklistOt.insert(checklistOt, { replace: true })
    }
  } catch (e) {
    throw new Error(`${e} (insertChecklistOt)`)
  }
}

const insertChecklistOtSubsis = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando subistemas de checklist')
    let rows = batchResponse.data.checklistOtSubsis.result.dataset
    for (let row of rows) {
      row.idchecklist_ot_subsis = row.idchecklist_ot_subsis.toString()
      row.idsubsis = row.idsubsis.toString()
    }
    await Vue.$offline.checklistOtSubsis.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertChecklistOtSubsis)`)
  }
}

const insertGrupoChecklistOt = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando grupos de checklist')
    let rows = batchResponse.data.grupoChecklistOt.result.dataset
    for (let row of rows) {
      row.idgrupo_checklist_ot = row.idgrupo_checklist_ot.toString()
      // preguntas generales sin checklist_ot_subsis
      if (row.idchecklist_ot_subsis) {
        row.idchecklist_ot_subsis = row.idchecklist_ot_subsis.toString()
      }
    }
    await Vue.$offline.grupoChecklistOt.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertGrupoChecklistOt)`)
  }
}

const insertAgrupacionPreguntaOt = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando agrupaciones de preguntas')
    let rows = batchResponse.data.agrupacionPreguntaOt.result.dataset
    for (let row of rows) {
      row.idagrupacion_pregunta_ot = row.idagrupacion_pregunta_ot.toString()
      row.idgrupo_checklist_ot = row.idgrupo_checklist_ot.toString()
    }
    await Vue.$offline.agrupacionPreguntaOt.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertAgrupacionPreguntaOt)`)
  }
}

const insertPreguntaChecklistOt = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando preguntas de checklist')
    let rows = batchResponse.data.preguntaChecklistOt.result.dataset
    for (let row of rows) {
      if (row.idagrupacion_pregunta_ot) {
        row.idagrupacion_pregunta_ot = row.idagrupacion_pregunta_ot.toString()
      }
      row.idgrupo_checklist_ot = row.idgrupo_checklist_ot.toString()
      row.idpregunta_checklist_ot = row.idpregunta_checklist_ot.toString()
      if (row.idorden_trabajo_matsist) {
        row.idorden_trabajo_matsist = row.idorden_trabajo_matsist.toString()
      }
    }
    await Vue.$offline.preguntaChecklistOt.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertPreguntaChecklistOt)`)
  }
}

const insertValorColumnaOt = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando valores de checklist')
    let rows = batchResponse.data.valorColumnaOt.result.dataset
    for (let row of rows) {
      row.idpregunta_checklist_ot = row.idpregunta_checklist_ot.toString()
      row.idagrupacion_pregunta_ot = row.idagrupacion_pregunta_ot.toString()
      row.idvalor_columna_ot = row.idvalor_columna_ot.toString()
    }
    await Vue.$offline.valorColumnaOt.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertValorColumnaOt)`)
  }
}

const insertTanomaliaChecklistOt = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando anomalías')
    let rows = batchResponse.data.tanomaliaChecklistOt.result.dataset
    for (let row of rows) {
      row.idtanomalia_checklist_ot = row.idtanomalia_checklist_ot.toString()
      row.idpregunta_checklist_ot = row.idpregunta_checklist_ot.toString()
    }
    await Vue.$offline.tanomaliaChecklistOt.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertTanomaliaChecklistOt)`)
  }
}

const insertAccionCorrectoraOt = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando acciones correctoras')
    let rows = batchResponse.data.accionCorrectoraOt.result.dataset
    for (let row of rows) {
      row.idaccion_correctora_ot = row.idaccion_correctora_ot.toString()
      row.idtanomalia_checklist_ot = row.idtanomalia_checklist_ot.toString()
    }
    await Vue.$offline.accionCorrectoraOt.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertAccionCorrectoraOt)`)
  }
}

const insertFicheroAnomalia = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando adjuntos de anomalías')
    let rows = batchResponse.data.ficheroAnomalia.result.dataset
    for (let row of rows) {
      row.idfichero = row.idfichero.toString()
      row.id = row.id.toString()
      try {
        row.data = await Vue.$api.ajaxDownload(row.url)
      } catch (e) {
        if (e && e.response && e.response.status >= 400) {
          // no hacer nada si no se puede descargar
          // no interpretar como un error de descarga del parte de trabajo
        } else {
          throw new Error(e)
        }
      }
    }
    await Vue.$offline.fichero.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertFicheroAnomalia)`)
  }
}

const insertFicheroAccionCorrectora = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando adjuntos de acciones correctoras')
    let rows = batchResponse.data.ficheroAccionCorrectora.result.dataset
    for (let row of rows) {
      row.idfichero = row.idfichero.toString()
      row.id = row.id.toString()
      try {
        row.data = await Vue.$api.ajaxDownload(row.url)
      } catch (e) {
        if (e && e.response && e.response.status >= 400) {
          // no hacer nada si no se puede descargar
          // no interpretar como un error de descarga del parte de trabajo
        } else {
          throw new Error(e)
        }
      }
    }
    await Vue.$offline.fichero.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertFicheroAccionCorrectora)`)
  }
}

const insertAccionEjec = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando acciones de orden de trabajo')
    let rows = batchResponse.data.accionEjec.result.dataset
    for (let row of rows) {
      row.idaccion_ejec = row.idaccion_ejec.toString()
      row.idorden_trabajo_matsist = row.idorden_trabajo_matsist.toString()
    }
    await Vue.$offline.accionEjec.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertAccionEjec)`)
  }
}

const insertOrdenTrabajoMant = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando revisiones de orden de trabajo')
    let rows = batchResponse.data.ordenTrabajoMant.result.dataset
    await Vue.$offline.ordenTrabajoMant.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertOrdenTrabajoMant)`)
  }
}

const insertLordenTrabajo = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando líneas de orden de trabajo')
    let rows = batchResponse.data.lordenTrabajo.result.dataset
    for (let row of rows) {
      row.idlorden_trabajo = row.idlorden_trabajo.toString()
      row.idsubsis = row.idsubsis.toString()
    }
    await Vue.$offline.lordenTrabajo.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertLordenTrabajo)`)
  }
}

const insertLordenTrabajoDetalle = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando escandallo de orden de trabajo')
    let rows = batchResponse.data.lordenTrabajoDetalle.result.dataset
    for (let row of rows) {
      row.idlorden_trabajo_detalle = row.idlorden_trabajo_detalle.toString()
      row.idlorden_trabajo = row.idlorden_trabajo.toString()
    }
    await Vue.$offline.lordenTrabajoDetalle.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertLordenTrabajoDetalle)`)
  }
}

const insertParteTrabajoMatsist = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando material afectado de parte de trabajo')
    let rows = batchResponse.data.parteTrabajoMatsist.result.dataset
    for (let row of rows) {
      row.idparte_trabajo_matsist = row.idparte_trabajo_matsist.toString()
      row.idorden_trabajo_matsist = row.idorden_trabajo_matsist.toString()
    }
    await Vue.$offline.parteTrabajoMatsist.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertParteTrabajoMatsist)`)
  }
}

const insertParteTrabajoTecnico = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando técnicos de partes de trabajo')
    let rows = batchResponse.data.parteTrabajoTecnico.result.dataset
    for (let row of rows) {
      row.idparte_trabajo_tecnico = row.idparte_trabajo_tecnico.toString()
    }
    await Vue.$offline.parteTrabajoTecnico.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertParteTrabajoTecnico)`)
  }
}

const insertLparteTrabajo = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando líneas de parte de trabajo')
    let rows = batchResponse.data.lparteTrabajo.result.dataset
    for (let row of rows) {
      row.idlparte_trabajo = row.idlparte_trabajo.toString()
      row.idlorden_trabajo = row.idlorden_trabajo.toString()
      row.idsubsis = row.idsubsis.toString()
    }
    await Vue.$offline.lparteTrabajo.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertLparteTrabajo)`)
  }
}

const insertTiempoTrabajado = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando tiempo trabajado de parte de trabajo')
    let rows = batchResponse.data.tiempoTrabajado.result.dataset
    for (let row of rows) {
      row.idtiempo_trabajado = row.idtiempo_trabajado.toString()
      row.idsubsis = row.idsubsis?.toString()
    }
    await Vue.$offline.tiempoTrabajado.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertTiempoTrabajado)`)
  }
}

const insertOrdenTrabajoSubsis = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando subistemas de orden de trabajo')
    let rows = batchResponse.data.ordenTrabajoSubsis.result.dataset
    for (let row of rows) {
      row.idorden_trabajo_subsis = row.idorden_trabajo_subsis.toString()
      row.idsubsis = row.idsubsis.toString()
    }
    await Vue.$offline.ordenTrabajoSubsis.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertOrdenTrabajoSubsis)`)
  }
}

const insertFicheroOrdenTrabajo = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando adjuntos de órdenes de trabajo')
    let rows = batchResponse.data.ficheroOrdenTrabajo.result.dataset
    for (let row of rows) {
      row.idfichero = row.idfichero.toString()
      row.id = row.id.toString()
      try {
        row.data = await Vue.$api.ajaxDownload(row.url)
      } catch (e) {
        if (e && e.response && e.response.status >= 400) {
          // no hacer nada si no se puede descargar
          // no interpretar como un error de descarga del parte de trabajo
        } else {
          throw new Error(e)
        }
      }
    }
    await Vue.$offline.fichero.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertFicheroOrdenTrabajo)`)
  }
}

const insertFicheroParteTrabajo = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando adjuntos de partes de trabajo')
    let rows = batchResponse.data.ficheroParteTrabajo.result.dataset
    for (let row of rows) {
      row.idfichero = row.idfichero.toString()
      row.id = row.id.toString()
      try {
        row.data = await Vue.$api.ajaxDownload(row.url)
      } catch (e) {
        if (e && e.response && e.response.status >= 400) {
          // no hacer nada si no se puede descargar
          // no interpretar como un error de descarga del parte de trabajo
        } else {
          throw new Error(e)
        }
      }
    }
    await Vue.$offline.fichero.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertFicheroParteTrabajo)`)
  }
}

const insertAlmacenesParte = async (Vue, almacenesParte, idparteTrabajo) => {
  try {
    Vue.$loading.setMessage('Actualizando almacenes del parte')
    for (let row of almacenesParte) {
      row.idv_almacenes_parte = uuidv4()
      row.idparte_trabajo = idparteTrabajo.toString()
    }
    await Vue.$offline.vAlmacenesParte.insert(almacenesParte, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertAlmacenesParte)`)
  }
}

const insertStock = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando stock')
    let rows = batchResponse.data.stock.result.dataset
    await Vue.$offline.stock.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertStock)`)
  }
}

const insertLstock = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando lineas de stock')
    let rows = batchResponse.data.lstock.result.dataset
    await Vue.$offline.lstock.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertLstock)`)
  }
}

const insertAnticipocli = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando anticipos de cliente')
    let rows = batchResponse.data.anticipocli.result.dataset
    await Vue.$offline.anticipocli.insert(rows, { replace: true})
  } catch (e) {
    throw new Error(`${e} (insertAnticipocli)`)
  }
}

const insertNotaOrdenTrabajo = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando notas')
    let rows = batchResponse.data.notaOrdenTrabajo.result.dataset
    await Vue.$offline.nota.insert(rows, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertNotaOrdenTrabajo)`)
  }
}

const insertNotaParteTrabajo = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando notas')
    let rows = batchResponse.data.notaParteTrabajo.result.dataset
    await Vue.$offline.nota.insert(rows, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertNotaParteTrabajo)`)
  }
}

const insertProyectoContable = async (Vue, batchResponse) => {
  try {
    Vue.$loading.setMessage('Actualizando proyectos contables')
    let rows = batchResponse.data.proyectoContable.result.dataset
    await Vue.$offline.proyectoContable.insert(rows, { replace: true })
  } catch (e) {
    throw new Error(`${e} (insertProyectoContable)`)
  }
}

const setParteTrabajoTecnicoDescargado = async (Vue, idparteTrabajoTecnico) => {
  pendingCalls.push({ method: 'parteTrabajo.setDescargado', params: { idparte_trabajo_tecnico: idparteTrabajoTecnico } })
  await Vue.$api.call('parteTrabajo.setDescargado', { idparte_trabajo_tecnico: idparteTrabajoTecnico })
}

export const downloadParteTrabajo = async (Vue, idparteTrabajo, idalmacen, idtecnico) => {
  const tables = Vue.$offline.db.tables
  Vue.$loading.showManual()
  try {
    const parte = await downloadParte(Vue, idparteTrabajo)
    await annadirCliente(Vue, parte.idcliente)
    await annadirSistema(Vue, parte.idsistema)
    await annadirFicheroSistema(Vue, parte.idsistema, idtecnico)
    await annadirTsistemaTdatosist(Vue, parte.idsistema)
    const ordenTrabajo = await downloadOrdenTrabajo(Vue, parte.idorden_trabajo)
    if (ordenTrabajo && ordenTrabajo.idbanco_precio) {
      annadirBancoPrecio(Vue, ordenTrabajo.idbanco_precio)
      annadirLbancoPrecio(Vue, ordenTrabajo.idbanco_precio)
      annadirBancoPrecioCapitulo(Vue, ordenTrabajo.idbanco_precio)
      annadirBancoPrecioGrupoDto(Vue, ordenTrabajo.idbanco_precio)
      annadirLbancoPrecioDetalle(Vue, ordenTrabajo.idbanco_precio)
    }
    await annadirClienteDireccion(Vue, parte.idcliente)
    await annadirClienteTelefono(Vue, parte.idcliente)
    await annadirClienteAviso(Vue, parte.idcliente)
    await annadirSubsis(Vue, parte.idsistema)
    await annadirMaterialSistema(Vue, parte.idsistema)
    await annadirDatoMaterialSistema(Vue, parte.idsistema)
    await annadirSistemaMant(Vue, parte.idsistema)
    await annadirSistemaCuota(Vue, parte.idsistema)
    await annadirSistemaTelefono(Vue, parte.idsistema)
    await annadirDatoSistema(Vue, parte.idsistema)
    await annadirOrdenTrabajoMatsist(Vue, parte.idorden_trabajo)
    await annadirSistemaTviacomunicacion(Vue, parte.idsistema)
    await annadirProyectoContable(Vue, ordenTrabajo.idproyecto_contable)
    const checklistOt = await downloadChecklistOt(Vue, parte.idorden_trabajo)
    if (checklistOt) {
      await annadirChecklistOtSubsis(Vue, checklistOt.idchecklist_ot)
      await annadirGrupoChecklistOt(Vue, checklistOt.idchecklist_ot)
      await annadirAgrupacionPreguntaOt(Vue, checklistOt.idchecklist_ot)
      await annadirPreguntaChecklistOt(Vue, checklistOt.idchecklist_ot)
      await annadirValorColumnaOt(Vue, checklistOt.idchecklist_ot)
      await annadirTanomaliaChecklistOt(Vue, checklistOt.idchecklist_ot)
      await annadirAccionCorrectoraOt(Vue, checklistOt.idchecklist_ot)
      await annadirFicheroAnomalia(Vue, checklistOt.idchecklist_ot)
      await annadirFicheroAccionCorrectora(Vue, checklistOt.idchecklist_ot)
    }
    await annadirAccionEjec(Vue, parte.idorden_trabajo)
    await annadirOrdenTrabajoMant(Vue, parte.idorden_trabajo)
    await annadirLordenTrabajo(Vue, parte.idorden_trabajo)
    await annadirLordenTrabajoDetalle(Vue, parte.idorden_trabajo)
    await annadirParteTrabajoMatsist(Vue, idparteTrabajo)
    await annadirParteTrabajoTecnico(Vue, idparteTrabajo)
    await annadirLparteTrabajo(Vue, idparteTrabajo)
    await annadirTiempoTrabajado(Vue, idparteTrabajo)
    await annadirSolicitudMaterial(Vue, idparteTrabajo)
    await annadirLsolicitudMaterial(Vue, idparteTrabajo)
    await annadirLlsolicitudMaterial(Vue, idparteTrabajo)
    await annadirOrdenTrabajoSubsis(Vue, parte.idorden_trabajo)
    await annadirFicheroOrdenTrabajo(Vue, parte.idorden_trabajo, idtecnico)
    await annadirFicheroParteTrabajo(Vue, idparteTrabajo, idtecnico)
    const almacenesParte = await downloadAlmacenesParte(Vue, idparteTrabajo)
    // descargo el stock de almacenes de obra o vehículo
    const almacenes = _.map(_.filter(almacenesParte, (item) => {
      return (
        item.talmacen_clasificacion === TALMACEN.clasificaciones.obra || item.talmacen_clasificacion === TALMACEN.clasificaciones.vehiculo
      )
    }), 'idalmacen')
    await annadirStock(Vue, almacenes)
    await annadirLstock(Vue, almacenes)
    await annadirAnticipocli(Vue, parte.idorden_trabajo)
    // TODO: se podría optimizar para llamar a la función Vue.$online.nota.buildApiFilterVisualizacion una sola vez.
    await annadirNotaParteTrabajo(Vue, idparteTrabajo, idtecnico)
    await annadirNotaOrdenTrabajo(Vue, parte.idorden_trabajo, idtecnico)
    const batchResponse = await Vue.$api.batchCall(pendingCalls)
    await insertCliente(Vue, batchResponse)
    await insertSistema(Vue, batchResponse)
    await insertFicheroSistema(Vue, batchResponse)
    await insertTsistemaTdatosist(Vue, batchResponse)
    if (ordenTrabajo && ordenTrabajo.idbanco_precio) {
      await insertBancoPrecio(Vue, batchResponse)
      await insertLbancoPrecio(Vue, batchResponse)
      await insertBancoPrecioCapitulo(Vue, batchResponse)
      await insertBancoPrecioGrupoDto(Vue, batchResponse)
      await insertLbancoPrecioDetalle(Vue, batchResponse)
    }
    await Vue.$offline.ordenTrabajo.insert(ordenTrabajo, { replace: true })
    await insertClienteDireccion(Vue, batchResponse)
    await insertClienteTelefono(Vue, batchResponse)
    await insertClienteAviso(Vue, batchResponse)
    await insertSubsis(Vue, batchResponse)
    await insertMaterialSistema(Vue, batchResponse)
    await insertDatoMaterialSistema(Vue, batchResponse)
    await insertSistemaMant(Vue, batchResponse)
    await insertSistemaCuota(Vue, batchResponse)
    await insertDatoSistema(Vue, batchResponse)
    await insertSistemaTviacomunicacnion(Vue, batchResponse)
    await insertSistemaTelefono(Vue, batchResponse)
    await insertOrdenTrabajoMatsist(Vue, batchResponse)
    if (checklistOt) {
      await insertChecklistOt(Vue, checklistOt)
      await insertChecklistOtSubsis(Vue, batchResponse)
      await insertGrupoChecklistOt(Vue, batchResponse)
      await insertAgrupacionPreguntaOt(Vue, batchResponse)
      await insertPreguntaChecklistOt(Vue, batchResponse)
      await insertValorColumnaOt(Vue, batchResponse)
      await insertTanomaliaChecklistOt(Vue, batchResponse)
      await insertAccionCorrectoraOt(Vue, batchResponse)
      await insertFicheroAnomalia(Vue, batchResponse)
      await insertFicheroAccionCorrectora(Vue, batchResponse)
    }
    await insertAccionEjec(Vue, batchResponse)
    await insertOrdenTrabajoMant(Vue, batchResponse)
    await insertLordenTrabajo(Vue, batchResponse)
    await insertLordenTrabajoDetalle(Vue, batchResponse)
    await Vue.$offline.parteTrabajo.insert(parte, { replace: true })
    await insertParteTrabajoMatsist(Vue, batchResponse)
    await insertParteTrabajoTecnico(Vue, batchResponse)
    await insertLparteTrabajo(Vue, batchResponse)
    await insertTiempoTrabajado(Vue, batchResponse)
    await insertOrdenTrabajoSubsis(Vue, batchResponse)
    await insertFicheroOrdenTrabajo(Vue, batchResponse)
    await insertFicheroParteTrabajo(Vue, batchResponse)
    await insertAlmacenesParte(Vue, almacenesParte, idparteTrabajo)
    await insertStock(Vue, batchResponse)
    await insertLstock(Vue, batchResponse)
    await insertAnticipocli(Vue, batchResponse)
    await insertSolicitudMaterial(Vue, batchResponse)
    await insertLsolicitudMaterial(Vue, batchResponse)
    await insertLlsolicitudMaterial(Vue, batchResponse)
    await insertNotaParteTrabajo(Vue, batchResponse)
    await insertNotaOrdenTrabajo(Vue, batchResponse)
    await insertProyectoContable(Vue, batchResponse)
    // descargar parte de trabajo
    const parteTrabajoTecnico = await Vue.$offline.parteTrabajoTecnico.select({
      where: Vue.$offline.db.op.and(
        tables.parte_trabajo_tecnico.idparte_trabajo.eq(idparteTrabajo),
        tables.parte_trabajo_tecnico.idtecnico.eq(idtecnico)
      )
    })
    await setParteTrabajoTecnicoDescargado(
      Vue, parteTrabajoTecnico[0].parte_trabajo_tecnico.idparte_trabajo_tecnico
    )
  } finally {
    pendingCalls = []
    Vue.$loading.hide()
  }
}

export const downloadTablasGlobales = async (Vue) => {
  let pendingCalls = []
  pendingCalls.push(
    { method: 'empresa.select', name: 'empresa', message: 'empresas' },
    { method: 'tcaja.select', name: 'tcaja', message: 'tipos de cajas' },
    { method: 'caja.select', name: 'caja', message: 'cajas' },
    { method: 'tformaPago.select', name: 'tformaPago', message: 'tipos de forma de pago' },
    { method: 'formaPago.select', name: 'formaPago', message: 'forma de pago' },
    { method: 'tactuacion.select', name: 'tactuacion', message: 'tipos de actuación' },
    { method: 'tsistema.select', name: 'tsistema', message: 'tipos de sistema' },
    { method: 'tincidenciaOt.select', name: 'tincidenciaOt', message: 'tipos de incidencia' },
    { method: 'ordenTrabajoPrioridad.select', name: 'ordenTrabajoPrioridad', message: 'prioridades' },
    { method: 'tparteTrabajo.select', name: 'tparteTrabajo', message: 'tipos de parte de trabajo' },
    { method: 'almacen.select', name: 'almacen', message: 'almacenes' },
    { method: 'talmacen.select', name: 'talmacen', message: 'tipos de almacén' },
    { method: 'tcierreParte.select', name: 'tcierreParte', message: 'tipos de cierre de parte' },
    { method: 'zona.select', name: 'zona', message: 'zonas' },
    { method: 'tactuacionTsistema.select', name: 'tactuacionTsistema', message: 'tipos de sitema' },
    { method: 'agenteExtintor.select', name: 'agenteExtintor', message: 'agentes extintores' },
    { method: 'agentePropelente.select', name: 'agentePropelente', message: 'agentes propelentes' },
    { method: 'timportanciaMaterial.select', name: 'timportanciaMaterial', message: 'importancia de materiales de sistema' },
    { method: 'fabricante.select', name: 'fabricante', message: 'fabricantes' },
    { method: 'familia.select', name: 'familia', message: 'familias' },
    { method: 'tsubfamilia.select', name: 'tsubfamilia', message: 'tipos de subfamilia' },
    { method: 'subfamilia.select', name: 'subfamilia', message: 'subfamilias' },
    { method: 'tsubsis.select', name: 'tsubsis', message: 'tipos de subsistema' },
    { method: 'tsistemaTsubsis.select', name: 'tsistemaTsubsis', message: 'tipos de subsistema por sistema' },
    { method: 'maccion.select', name: 'maccion', message: 'tipos de acción' },
    { method: 'maccionTsubfam.select', name: 'maccionTsubfam', message: 'subfamilias por tipo de acción' },
    { method: 'timpuesto.select', name: 'timpuesto', message: 'impuestos' },
    { method: 'toperacionImpuesto.select', name: 'toperacionImpuesto', message: 'tipos de operación de impuestos' },
    { method: 'articulo.select', name: 'articulo', message: 'artículos' },
    { method: 'articuloCodbarras.select', name: 'articuloCodbarras', message: 'códigos de barra de artículos' },
    { method: 'plantillaArticulo.select', name: 'plantillaArticulo', message: 'plantillas de artículos' },
    { method: 'plantillaGrupoArticulo.select', name: 'plantillaGrupoArticulo', message: 'grupos de plantillas de artículos' },
    { method: 'lplantillaArticulo.select', name: 'lplantillaArticulo', message: 'artículos de plantillas de artículos' },
    { method: 'periodoRevision.select', name: 'periodoRevision', message: 'períodos de revisión' },
    { method: 'checklist.select', name: 'checklist', message: 'definición de checklists' },
    { method: 'grupoChecklist.select', name: 'grupoChecklist', message: 'definición de grupos de checklist' },
    { method: 'grupoChecklistSubfamilia.select', name: 'grupoChecklistSubfamilia', message: 'definición de subfamilias de checklist' },
    { method: 'agrupacionPregunta.select', name: 'agrupacionPregunta', message: 'definición de agrupaciones de preguntas' },
    { method: 'tequipoMedicion.select', name: 'tequipoMedicion', message: 'tipos de equipo de medición' },
    { method: 'equipoMedicion.select', name: 'equipoMedicion', message: 'equipos de medición' },
    { method: 'gradoAnomalia.select', name: 'gradoAnomalia', message: 'grados de anomalía' },
    { method: 'preguntaChecklist.select', name: 'preguntaChecklist', message: 'definición de preguntas de checklist' },
    { method: 'valorColumna.select', name: 'valorColumna', message: 'denifición de valores de checklist' },
    { method: 'tanomaliaChecklist.select', name: 'tanomaliaChecklist', message: 'definición de anomalías de checklist' },
    { method: 'accionCorrectora.select', name: 'accionCorrectora', message: 'definición de acciones correctoras' },
    { method: 'empleado.select', name: 'empleado', message: 'empleados' },
    { method: 'empleadoCaja.select', name: 'empleadoCaja', message: 'cajas por empleado' },
    { method: 'tecnico.select', name: 'tecnico', message: 'técnicos' },
    { method: 'tfichero.select', name: 'tfichero', message: 'tipos de fichero' },
    { method: 'tdatoArticulo.select', name: 'tdatoArticulo', message: 'tipos de dato de artículo' },
    { method: 'tdatoArticuloValor.select', name: 'tdatoArticuloValor', message: 'valores de tipos de dato de artículo' },
    { method: 'datoArticulo.select', name: 'datoArticulo', message: 'datos de artículo' },
    { method: 'fichaTecnica.select', name: 'fichaTecnica', message: 'fichas técnicas' },
    { method: 'grupoFichaTecnica.select', name: 'grupoFichaTecnica', message: 'grupos de ficha técnica' },
    { method: 'articuloEscandallo.select', name: 'articuloEscandallo', message: 'escandallos de artículo' },
    { method: 'tmotivoNofacturable.select', name: 'tmotivoNofacturable', message: 'motivos de no facturación' },
    { method: 'maccionFact.select', name: 'maccionFact', message: 'artículos de facturación por tipo de acción' },
    { method: 'regimenFiscal.select', name: 'regimenFiscal', message: 'regímenes fiscales' },
    { method: 'nivelSatisfaccion.select', name: 'nivelSatisfaccion', message: 'niveles de satisfacción' },
    { method: 'parametro.select', name: 'parametro', message: 'parámetros' },
    { method: 'ttarea.select', name: 'ttarea', message: 'tipos de tarea' },
    { method: 'tecnicoTtarea.select', name: 'tecnicoTtarea', message: 'tipos de tarea de técnicos' },
    { method: 'tarifa.select', name: 'tarifa', message: 'tarifa' },
    { method: 'idioma.select', name: 'idioma', message: 'idiomas' },
    { method: 'traduccion.select', name: 'traduccion', message: 'traducciones' },
    { method: 'ttelefono.select', name: 'ttelefono', message: 'tipos de teléfono' },
    { method: 'tmotivoBloqueoOt.select', name: 'tmotivoBloqueoOt', message: 'tipos de bloqueo de orden de trabajo' },
    { method: 'tservicioContratado.select', name: 'tservicioContratado', message: 'tipos de servicio contratado' },
    { method: 'conceptoCuota.select', name: 'conceptoCuota', message: 'conceptos de cuotas' },
    { method: 'conceptoCuotaTservicio.select', name: 'conceptoCuotaTservicio', message: 'tipos de servicios por conceptos de cuotas' },
    { method: 'tstock.select', name: 'tstock', message: 'tipos de stock' },
    { method: 'marca.select', name: 'marca', message: 'marcas' },
    { method: 'vehiculo.select', name: 'vehiculo', message: 'vehículos' },
    { method: 'grupoDescuento.select', name: 'grupoDescuento', message: 'grupos de descuento' },
    { method: 'tdatoSistema.select', name: 'tdatoSistema', message: 'tipos de datos de sistema' },
    { method: 'tviacomunicacion.select', name: 'tviacomunicacion', message: 'tipos de vías de comunicación' },
    { method: 'ttransmisor.select', name: 'ttransmisor', message: 'tipos de transmisores' },
    { method: 'grupoEmpleado.select', name: 'grupoEmpleado', message: 'grupos de usuario' },
    { method: 'tnota.select', name: 'tnota', message: 'tipos de notas' },
    { method: 'estOrdenTrabajo.select', name: 'estOrdenTrabajo', message: 'estados de órdenes de trabajo' },
  )
  Vue.$loading.showManual('Descargando datos globales...')
  try {
    const batchResponse = await Vue.$api.batchCall(pendingCalls)
    for (let call in pendingCalls) {
      Vue.$loading.showManual(`Actualizando ${pendingCalls[call].message}`)
      if (batchResponse.data[pendingCalls[call].name].result && batchResponse.data[pendingCalls[call].name].result.dataset) {
        const dataset = batchResponse.data[pendingCalls[call].name].result.dataset
        const offlineResource = Vue.$offline[pendingCalls[call].name]
        // insert or replace
        await offlineResource.insert(dataset, { replace: true })
        // delete (ya no existe)
        if (offlineResource.pkName) {
          const inPk = _.map(dataset, offlineResource.pkName)
          // TODO: https://github.com/google/lovefield/issues/274
          inPk.push(-1)
          await offlineResource.delete({ where: Vue.$offline.db.op.not(offlineResource.pk.in(inPk)) })
        }
      } else {
        throw new Error((`No se puede descargar ${pendingCalls[call].name}. Error: ${batchResponse.data[pendingCalls[call].name].error.message}`))
      }
    }
  } finally {
    Vue.$loading.hide()
  }
}
